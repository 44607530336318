export class ImportHelper {

  skillsEnabled;
  progressEnabled;
  buddyEnabled;

  constructor(features) {
    this.skillsEnabled = features.includes("skills");
    this.progressEnabled = features.includes("progress");
    this.buddyEnabled = features.includes("buddy");
  }

  initInterventionAttributes(jsonObject, studyId) {
    const attributes = jsonObject.interventionDetails.attributes;
    attributes.study_id = studyId;
    attributes.is_active = false;
    attributes.unlock_diaries = [];
    if (
      !attributes.picture ||
      attributes.picture.startsWith("https://") ||
      attributes.picture.startsWith("/var")
    ) {
      attributes.picture = "";
    }
    //export from old cms has no translations array -> only exported in de
    if (!attributes.translations || attributes.translations.length === 0) {
      attributes.translations = [{
        locale: "de",
        title: attributes.title,
        description: attributes.description,
      }];
    }
    if (
      !attributes.intervention_type ||
      attributes.intervention_type === "accompanied_and_unaccompanied"
    ) {
      attributes.intervention_type = "unaccompanied";
    }
    if (this.skillsEnabled) {
      if (attributes.gamification) {
        attributes.gamification.skills_order = [];
      } else {
        attributes.gamification = { skills_order: [] };
      }
    } else if (attributes.gamification) {
      delete attributes.gamification.skills_order;
    }
    if (this.progressEnabled) {
      if (!attributes.gamification) {
        attributes.gamification = { progressbars_enabled: 0 };
      } else if (!attributes.gamification.progressbars_enabled) {
        attributes.gamification.progressbars_enabled = 0;
      }
    } else if (attributes.gamification) {
      delete attributes.gamification.progressbars_enabled;
    }
    if (this.buddyEnabled) {
      attributes.buddy_support = false;
    } else if (attributes.buddy_support || attributes.buddy_support === 0) {
      delete attributes["buddy_support"];
    }
    return attributes;
  }

  initLessonAttributes(lesson, skillIds) {
    const attributes = lesson.details;
    //export from old cms has no locales array
    if (!attributes.locales) {
      attributes.locales = ["de", "en"];
    }
    if (!attributes.page_color) {
      attributes.page_color = "";
    }
    if (attributes.page_color.startsWith("https://")) {
      const pageColor = attributes.page_color;
      const color = pageColor.slice(pageColor.lastIndexOf("/") + 1);
      attributes.page_color = color.split(".")[0];
    }
    attributes.unlock_diaries = [];
    if (this.skillsEnabled) {
      const newSkills = [];
      for (const s in attributes.skills) {
        const oldSkillId = parseInt(attributes.skills[s], 10);
        const newSkillId = skillIds.get(oldSkillId);
        if (newSkillId !== undefined) {
          newSkills.push(newSkillId);
        }
      }
      attributes.skills = newSkills;
    } else {
      delete attributes.skills;
    }
    //export from old cms has no translations array -> only exported in de
    if (
      (!attributes.translations ||
        attributes.translations.length === 0) &&
      attributes.title !== undefined &&
      attributes.description !== undefined
    ) {
      attributes.translations = [{
        locale: "de",
        title: attributes.title,
        description: attributes.description,
      }];
    }
    const newTranslations = [];
    //check if title or description null
    for (const t in attributes.translations) {
      const translation = attributes.translations[t];
      if (translation.title) {
        const copy = JSON.parse(JSON.stringify(translation));
        newTranslations.push(copy);
      }
    }
    attributes.translations = newTranslations;
    return attributes;
  }

  initLessonElements(lesson) {
    const elements = lesson.elements;
    for (const elem in elements) {
      const element = elements[elem];
      switch (element.elementtype) {
        case "elements/headlines":
          if (element.color === undefined) {
            element.color = "#000000";
          }
          break;
        case "elements/texts":
          element.translations = element.translations.filter(t => !!t.text);
          break;
        case "elements/pages":
          if (!element.color) {
            element.color = "";
          }
          break;
        case "elements/questions":
          if (element.questiontype === "Slider" && element.values.start === undefined) {
            element.values.start = element.values.min;
          }
          element.translations = element.translations.filter(t => !!t.question);
          break;
        default:
      }
    }
    return elements;
  }
}
